import { useState, useEffect } from "react";

export function useReceiveDataByPostMessage() {

  const [summary, setSummary] = useState("");
  const [milmoRecorderOrigin, setMilmoRecorderOrigin] = useState<string | null>(null);

  useEffect(() => {
    const getMilmoRecorderConfig = async () => {
      const response = await fetch("/api/integration/milmo-recorder-config");
      if (!response.ok) {
        return;
      }
      const data = await response.json();
      setMilmoRecorderOrigin(data.origin);
    }
    getMilmoRecorderConfig();
  }, []);

  useEffect(() => {
    // ミルモレコーダーから送信されるデータの受信待機
    if (!milmoRecorderOrigin) return;
    const acceptDomainPattern = new RegExp(milmoRecorderOrigin);
    window.addEventListener('message', (event: MessageEvent) => {
      if (!acceptDomainPattern?.test(event.origin)) return;
      if (event.data.action === 'minutes-summary') {
        if (event.data.summary) {
          setSummary(event.data.summary);
        }
      }
    });
  }, [milmoRecorderOrigin]);

  return { summary, setSummary, milmoRecorderOrigin };
}
